<template>
  <el-dialog append-to-body title="Select Block" custom-class="dragDropSelectorDialog" :visible.sync="dialogVisible" width="600px">
    <div class="dialogContent">
      <div class="dragDropBlockSelector">
        <el-row class="blocksList" :gutter="20">
          <el-col class="subHeader" :span="24">Blocks</el-col>
          <el-col :span="6" v-for="(block, index) in blocks" :key="index" v-show="!block.type.startsWith('column')">
            <div class="blockContainer" v-on:click="onBlockSelected(block)">
              <div class="block">
                <div class="blockIcon" :style="{ 'background-image': 'url(' + block.icon + ')' }"></div>
                <div class="blockText">
                  {{ block.name }}
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row class="blocksList" :gutter="20" style="margin-top: 20px !important">
          <el-col class="subHeader" :span="24">Rows</el-col>
          <el-col :span="6" v-for="(block, index) in blocks" :key="index" v-show="block.type.startsWith('column')">
            <div class="blockContainer" v-on:click="onBlockSelected(block)">
              <div class="block">
                <div class="blockIcon" :style="{ 'background-image': 'url(' + block.icon + ')' }"></div>
                <div class="blockText">
                  {{ block.name }}
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </el-dialog>
</template>

<style lang="scss">
.dragDropSelectorDialog {
  .el-dialog__header {
    border-bottom: 1px solid #ddd;
  }

  .dialogContent {
    .dragDropBlockSelector {
      .blocksList {
        margin: 0px !important;
        width: 100%;
        .blockContainer {
          text-align: center;
          padding: 7px 5px;

          .block {
            margin-top: 5px;
            border: 1px solid #bfbfbf;
            border-radius: 3px;
            background: #f1f1f1;
            padding-top: 12px;
            padding-bottom: 12px;
            cursor: pointer;

            .blockIcon {
              font-size: 12px;
              height: 27px;
              text-align: center;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              margin: auto;
            }

            .blockText {
              margin-top: 5px;
              font-size: 12px;
            }

            &:hover {
              -webkit-box-shadow: 0px 0px 5px 0px rgba(207, 207, 207, 2);
              -moz-box-shadow: 0px 0px 5px 0px rgba(207, 207, 207, 2);
              box-shadow: 0px 0px 5px 0px rgba(207, 207, 207, 2);
            }
          }
        }

        .subHeader {
          padding-left: 15px !important;
          color: #000;
        }
      }
    }

    .dragDropBlockSelector:hover {
      .addButton {
        opacity: 1;
      }
    }
  }
}
</style>

<script>
export default {
  name: 'dragDropBlockSeperator',
  components: {},
  data() {
    return {
      dialogVisible: false,
      blockIndex: null,
      columnIndex: null,
      columnVerticalIndex: null,
      blocks: [
        {
          type: 'text',
          name: 'Text',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png',
          mobileVisible: true,
          desktopVisible: true,
          content: {
            html: `<p style="text-align:center;"><span style="font-size:21px;"><strong>Our online store is now open!</strong></span></p><p>We're excited to announce that our products are available for purchase online. Now you can shop and browse from the comfort of your own home.</p>`,
            margin: {
              top: 20,
              left: 20,
              right: 20,
              bottom: 20
            }
          }
        },
        {
          type: 'image',
          name: 'Image',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/image.png',
          mobileVisible: true,
          desktopVisible: true,
          content: {
            src: null,
            srcMode: 'upload',
            width: 100,
            alignment: 'center',
            radius: 0,
            link: null
          }
        },
        {
          type: 'button',
          name: 'Button ',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/button.png',
          mobileVisible: true,
          desktopVisible: true,
          closeOnClick: true,
          convertOnClick: false,
          content: {
            text: 'Learn More',
            textColor: '#FFFFFF',
            buttonColor: '#1833D4',
            fontSize: 14,
            link: '',
            borderRadius: 5,
            alignment: 'center',
            radius: 5,
            margin: {
              top: 20,
              left: 20,
              right: 20,
              bottom: 20
            },
            insidePadding: {
              top: 16,
              left: 25,
              right: 25,
              bottom: 16
            }
          }
        },
        {
          type: 'coupon-code',
          name: 'Coupon Code ',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/coupon-code-block.png',
          mobileVisible: true,
          desktopVisible: true,
          content: {
            text: {
              text: 'SDFKD',
              color: '#000000',
              fontFamily: 'Arial',
              fontSize: 30,
              fontWeight: 'bold',
              letterSpacing: 0,
              alignment: 'center'
            },
            backgroundColor: '#EEEEEE',
            border: {
              type: 'dashed',
              color: '#000000',
              width: 2
            },
            radius: 5,
            margin: {
              top: 20,
              left: 20,
              right: 20,
              bottom: 20
            },
            insidePadding: {
              top: 8,
              left: 25,
              right: 15,
              bottom: 8
            }
          }
        },
        {
          type: 'form',
          name: 'Form',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/divider.png',
          mobileVisible: true,
          desktopVisible: true,
          content: {
            width: 100,
            margin: {
              top: 20,
              left: 20,
              right: 20,
              bottom: 20
            },
            fields: [
              {
                name: 'Email',
                type: 'text',
                columnName: 'email',
                placeholder: 'Enter Email',
                labelText: 'Email',
                required: true,
                editMode: false,
                inputUiType: 'input',
                inputUiAllowedValues: [],
                inputDateTypeMeta: {
                  format: 'ddmmyyyy',
                  seperator: '/'
                }
              }
            ],
            button: {
              buttonColor: '#000000',
              text: 'Subscribe',
              textColor: '#FFFFFF',
              fontSize: 17,
              fontFamily: 'Arial',
              fontWeight: 'bold',
              letterSpacing: 1,

              widthType: 'full',
              radius: 5,

              textPadding: {
                top: 8,
                left: 10,
                right: 10,
                bottom: 8
              },
              padding: {
                top: 5,
                left: 0,
                right: 0,
                bottom: 5
              },
              border: {
                width: 0,
                color: '#000000'
              }
            },
            label: {
              visible: false,
              color: '#786B6A',
              fontSize: 12
            },
            input: {
              background: '#FFFFFF',
              width: 100,
              height: 40,
              padding: 10,
              textSize: 14,
              textColor: '#000000',
              placeholderColor: '#A1A1A1FF',
              borderColor: '#A1A1A1FF',
              borderWidth: 1,
              borderRadius: 5,
              marginBottom: 18,
              radioButtonColor: '#000000',
              radioButtonTextColor: '#000000'
            }
          }
        },
        {
          type: 'divider',
          name: 'Divider',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/divider.png',
          mobileVisible: true,
          desktopVisible: true,
          content: {
            height: 2,
            color: '#D1D6ED',
            width: 100,
            rowPadding: {
              top: 20,
              left: 20,
              right: 20,
              bottom: 20
            }
          }
        },
        // Custom HTML
        {
          type: 'custom-html',
          name: 'Custom HTML',
          mobileVisible: true,
          desktopVisible: true,
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/custom-html.png',
          content: {
            html: '<span>HTML Snippet Goes Here.....</span>'
          }
        },
        {
          type: 'columns-one',
          name: '1 Column',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/single-column.png',
          hideOnMobile: false,
          hideOnDesktop: false,
          content: {
            flex: [1],
            columns: [[]]
          }
        },
        {
          type: 'columns-two-first-smaller',
          name: '2 Columns',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/right-bigger.png',
          hideOnMobile: false,
          hideOnDesktop: false,
          content: {
            flex: [0.35, 0.65],
            columns: [[], []]
          }
        },
        {
          type: 'columns-two-equal',
          name: '2 Columns',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/two-columns.png',
          hideOnMobile: false,
          hideOnDesktop: false,
          content: {
            flex: [0.5, 0.5],
            columns: [[], []]
          }
        },
        {
          type: 'columns-two-last-smaller',
          name: '2 Columns',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/left-bigger.png',
          hideOnMobile: false,
          hideOnDesktop: false,
          content: {
            flex: [0.65, 0.35],
            columns: [[], []]
          }
        },
        {
          type: 'columns-three',
          name: '3 Columns',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/three-columns.png',
          hideOnMobile: false,
          hideOnDesktop: false,
          content: {
            flex: [0.33, 0.33, 0.33],
            columns: [[], [], []]
          }
        },
        {
          type: 'columns-four',
          name: '4 Columns',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/four-columns.png',
          hideOnMobile: false,
          hideOnDesktop: false,
          content: {
            flex: [0.25, 0.25, 0.25, 0.25],
            columns: [[], [], [], []]
          }
        }
      ]
    };
  },

  methods: {
    showPopup(index, columnIndex, columnVerticalIndex) {
      console.log('show popup', index, columnIndex, columnIndex);
      this.dialogVisible = true;
      this.blockIndex = index;
      this.columnIndex = columnIndex;
      this.columnVerticalIndex = columnVerticalIndex;
    },

    onBlockSelected(block) {
      this.dialogVisible = false;
      let newBlock = JSON.parse(JSON.stringify(block));
      newBlock.id = Date.now();

      let data = {
        block: newBlock,
        index: this.blockIndex,
        columnIndex: this.columnIndex,
        columnVerticalIndex: this.columnVerticalIndex
      };
      this.$emit('blockSelected', data);
    }
  },

  mounted() {
    for (let i = 0; i < this.blocks.length; i++) {
      let block = this.blocks[i];
      // Row paddding
      if (!block.content.rowPadding) {
        block.content.rowPadding = {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        };
      }

      // Block paddding
      if (!block.content.margin) {
        block.content.margin = {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        };
      }

      // section background
      if (!block.content.rowBackgroundColor) {
        block.content.rowBackgroundColor = '#FFFFFF00';
      }
      // section background image
      if (!block.content.backgroundImage) {
        block.content.rowBackgroundImage = null;
      }
    }
  }
};
</script>
