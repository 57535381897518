<template>
  <div id="webMessagePreview" class="webMessagePreview" v-if="visible">
    <div id="previewIframeContainer" class="previewIframeContainer">
      <!-- Iframe Place Holder -->
    </div>

    <!-- Preview Footer -->
    <el-row class="previewFooter">
      <el-col class="text-gray-50" :span="1"> &nbsp;</el-col>
      <el-col class="text-gray-50 mt-1.5" :span="9"> * Form will not be submitted in preview mode. </el-col>
      <el-col :span="12">
        <el-radio-group v-model="previewDeviceType" @change="previewDeviceTypeChange">
          <el-radio-button label="desktop"> <i class="el-icon-monitor"></i> &nbsp; Desktop </el-radio-button>
          <el-radio-button label="mobile"> <i class="el-icon-mobile-phone"></i> &nbsp; Mobile </el-radio-button>
        </el-radio-group>
      </el-col>
      <el-col :span="2"><el-button class="closeBtn" type="danger" @click="closePreviewPopup">Close Preview</el-button> </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" src="./createWebMessageCampaign.scss"></style>

<script>
export default {
  data() {
    return {
      visible: false,
      webMessageJson: null,

      previewDeviceType: 'desktop'
    };
  },
  components: {},
  computed: {},
  methods: {
    renderPopupIframe() {
      requestAnimationFrame(() => {
        // console.log('render popup preview', this.webMessageJson);

        // Remove existing iframe if present.
        let iframeContainerElement = document.getElementById('previewIframeContainer');
        while (iframeContainerElement.lastElementChild) {
          iframeContainerElement.removeChild(iframeContainerElement.lastElementChild);
        }

        // Set background image for flyout.
        let isFlyout = this.webMessageJson.appereance.type == 'flyout';
        iframeContainerElement.style['background'] = 'white';
        if (isFlyout) {
          iframeContainerElement.style['background-image'] = 'url("https://static.growlytics.in/dashboard-assets/assets/img/webMessage/poppup_overlay_image.png")';
          iframeContainerElement.style['background-size'] = 'cover';
          iframeContainerElement.style.width = this.previewDeviceType == 'mobile' ? '400px' : '100%';
        }

        // Prepare IFrame To Show Message.
        let iframeId = `grw-web-message-abc-test`;
        let iframe = document.createElement('iframe');
        iframe.setAttribute('id', iframeId);
        iframe.setAttribute('allow', 'clipboard-read; clipboard-write');
        iframe.setAttribute('src', 'https://webmessage-v2.growlytics.in');
        // iframe.setAttribute('src', 'http://localhost:4000'); // For Development
        iframe.style.opacity = '0';
        iframe.style.width = this.previewDeviceType == 'mobile' ? '400px' : '100%';
        iframe.style['z-index'] = '999999999999';
        iframe.style.border = 'none';
        iframe.style.height = '93vh'; // SET AFTER PAGE LOAD.
        iframe.style.background = 'transparent'; // SET AFTER PAGE LOAD.

        iframe.onload = () => {
          // Prepare json to send to iframe
          let jsonForIframe = {
            message: 'growlytics-show-web-message',
            data: {
              data: this.webMessageJson
            },
            id: 'abc-test',
            isTestPreview: !isFlyout,
            hidePoweredBy: true,
            parentWidth: this.previewDeviceType == 'mobile' ? 400 : window.document.body.clientWidth
          };
          iframe.contentWindow.postMessage(jsonForIframe, '*');
        };

        // Embed IFrame.
        iframeContainerElement.appendChild(iframe);
      });
    },

    handleIframeMessage(message) {
      let payload = message.data;
      let action = payload.action;
      let data = payload.data;

      if (payload && payload.source == 'Growlytics') {
        if (action == 'growlytics-message-resize') {
          let iframe = document.getElementById(`grw-web-message-${data.id}`);
          if (iframe) {
            if (data.isPopup) {
              // Check for popup positioning.
              iframe.style.position = 'fixed';
              iframe.style.left = this.previewDeviceType == 'mobile' ? '35%' : '0';
              iframe.style.right = '0';
              iframe.style.top = '1vh';
              iframe.style.bottom = '0';
              iframe.style.width = this.previewDeviceType == 'mobile' ? '400px' : '100%';
              iframe.style.height = '91vh';
            } else if (data.isFlyout) {
              // Check for flyout positioning.

              iframe.style.width = data.width;
              iframe.style.height = data.height;

              iframe.style.position = 'fixed';
              iframe.style.top = 'auto';
              iframe.style.bottom = 'auto';

              if (data.isMobileMode) {
                iframe.style.left = '0';
                iframe.style.right = '0';
                iframe.style.width = this.previewDeviceType == 'mobile' ? '400px' : '100%';

                // Set Horizontal Position
                // if (data.flyoutPosition.x == 'left') iframe.style.left = '20px';
                // else if (data.flyoutPosition.x == 'right') iframe.style.right = '20px';
                // else if (data.flyoutPosition.x == 'center') {
                iframe.style.margin = 'auto';
                iframe.style.left = '0';
                iframe.style.right = '0';
                // }
              } else {
                if (iframe.style.width == '100%') {
                  iframe.style.left = '0';
                  iframe.style.right = '0';
                } else {
                  iframe.style.left = 'auto';
                  iframe.style.right = 'auto';
                }

                // Set Horizontal Position
                if (data.flyoutPosition.x == 'left') iframe.style.left = '20px';
                else if (data.flyoutPosition.x == 'right') iframe.style.right = '20px';
                else if (data.flyoutPosition.x == 'center') {
                  iframe.style.margin = 'auto';
                  iframe.style.left = '0';
                  iframe.style.right = '0';
                }
              }

              // Set vertical Position
              if (data.flyoutPosition.y == 'top') iframe.style.top = '20px';
              else if (data.flyoutPosition.y == 'bottom') iframe.style.bottom = '70px';
            }

            iframe.style.opacity = '1';
          }
        } else if (action == 'growlytics-close-popup') {
          // Close Popup.
          this.infoToast('Popup will disappear at this point.');
        } else if (action == 'growlytics-form-submitted') {
          // Form Submitted.
          this.infoToast('Form not be submitted in preview mode.');
        }
      }
    },

    previewDeviceTypeChange() {
      this.renderPopupIframe();
    },

    showPreviewPopup(webMessageJson) {
      this.webMessageJson = webMessageJson;
      this.previewDeviceType = 'desktop';
      this.visible = true;
      // Add Listener For Iframe Events.
      window.addEventListener('message', this.handleIframeMessage, false);
      this.renderPopupIframe();
    },

    closePreviewPopup() {
      // console.log('At Destroy,,,');
      // Add Listener For Iframe Events.
      window.removeEventListener('message', this.handleIframeMessage, false);
      this.visible = false;
    }
  }
};
</script>
