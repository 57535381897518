<template>
  <div
    class="blockSection"
    :id="'block_' + block.id"
    v-if="block"
    :style="getCalculatedContainerStyles()"
    :class="{
      desktopHidden: !block.desktopVisible,
      mobileHidden: !block.mobileVisible
    }"
  >
    <!-- Hide on mobile/desktop  -->
    <div class="mobileShowHideDiv to-be-removed">
      <el-tooltip effect="dark" content="Hide In Desktop" placement="bottom" v-if="!block.desktopVisible">
        <div class="mobileShowHideDiv_btn bg-secondary-600 text-white text-center rounded-md">
          <i class="fa-regular fa-display"></i>
          <i class="fa-solid fa-slash"></i>
        </div>
      </el-tooltip>
      <el-tooltip effect="dark" content="Hide In Mobile" placement="bottom" v-if="!block.mobileVisible">
        <div class="mobileShowHideDiv_btn bg-secondary-600 text-white text-center rounded-md">
          <i class="fa-regular fa-mobile"></i>
          <i class="fa-solid fa-slash"></i>
        </div>
      </el-tooltip>
    </div>

    <!-- Text Block -->
    <div
      class="textSection"
      v-if="block.type == 'text'"
      :style="{
        margin: block.content.margin.top + 'px ' + block.content.margin.right + 'px ' + block.content.margin.bottom + 'px ' + block.content.margin.left + 'px '
      }"
    >
      <span v-html="block.content.html"></span>
    </div>

    <!-- Image Section -->
    <div
      class="imageSection"
      v-if="block.type == 'image'"
      :style="{
        margin: block.content.margin.top + 'px ' + block.content.margin.right + 'px ' + block.content.margin.bottom + 'px ' + block.content.margin.left + 'px ',
        'text-align': block.content.alignment
      }"
    >
      <div v-if="!block.content.src" class="imagePreview"><i class="fa fa-picture-o"></i></div>
      <img
        v-else-if="!block.content.link"
        class="section-img"
        :src="block.content.src"
        :style="{
          width: block.content.width + '% !important',
          'border-radius': block.content.radius + 'px'
        }"
      />
      <a v-else :href="block.content.link" _target="blank">
        <img
          class="section-img"
          :src="block.content.src"
          :style="{
            width: block.content.width + '% !important',
            'border-radius': block.content.radius + 'px'
          }"
        />
      </a>
    </div>

    <!-- Button Section -->
    <div v-if="block.type == 'button'" class="buttonSection" :style="{ 'text-align': block.content.alignment }">
      <div
        v-if="!block.content.link"
        class="buttonStyle"
        :class="{ 'grw-message-close': block.closeOnClick, 'grw-message-convert': block.convertOnClick }"
        :style="{
          margin: block.content.margin.top + 'px ' + block.content.margin.right + 'px ' + block.content.margin.bottom + 'px ' + block.content.margin.left + 'px ',
          padding: block.content.insidePadding.top + 'px ' + block.content.insidePadding.right + 'px ' + block.content.insidePadding.bottom + 'px ' + block.content.insidePadding.left + 'px ',
          'font-size': block.content.fontSize + 'px',
          'background-color': block.content.buttonColor,
          color: block.content.textColor,
          'border-radius': block.content.radius + 'px'
        }"
      >
        {{ block.content.text }}
      </div>
      <a
        v-else
        :href="block.content.link"
        class="buttonStyle"
        _target="blank"
        :class="{ 'grw-message-close': block.closeOnClick, 'grw-message-convert': block.convertOnClick }"
        :style="{
          padding: block.content.insidePadding.top + 'px ' + block.content.insidePadding.right + 'px ' + block.content.insidePadding.bottom + 'px ' + block.content.insidePadding.left + 'px ',
          margin: block.content.margin.top + 'px ' + block.content.margin.right + 'px ' + block.content.margin.bottom + 'px ' + block.content.margin.left + 'px ',
          'font-size': block.content.fontSize + 'px',
          'background-color': block.content.buttonColor,
          color: block.content.textColor,
          'border-radius': block.content.radius + 'px'
        }"
      >
        {{ block.content.text }}
      </a>
    </div>

    <!-- Coupon Block -->
    <div v-if="block.type == 'coupon-code'">
      <div
        class="couponCodeSection"
        :style="{
          margin: block.content.margin.top + 'px ' + block.content.margin.right + 'px ' + block.content.margin.bottom + 'px ' + block.content.margin.left + 'px ',
          padding: block.content.insidePadding.top + 'px ' + block.content.insidePadding.right + 'px ' + block.content.insidePadding.bottom + 'px ' + block.content.insidePadding.left + 'px ',
          'background-color': block.content.backgroundColor,

          color: block.content.text.color,
          'font-size': block.content.text.fontSize + 'px',
          'font-weight': block.content.text.fontWeight,
          'letter-spacing': block.content.text.letterSpacing + 'px',

          'border-radius': block.content.radius + 'px',
          'border-style': block.content.border.type,
          'border-width': block.content.border.width + 'px',
          'border-color': block.content.border.color
        }"
      >
        <div style="width: 100%; display: flex; display: flex; flex-direction: row; flex: 1 1 0%; align-items: center; justify-content: center">
          <div style="flex: 1" :style="{ 'text-align': block.content.text.alignment, 'font-family': block.content.text.fontFamily + ' !important' }">{{ block.content.text.text }}</div>
          <svg style="cursor: pointer; flex-shrink: 0; display: inline-block" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" :style="{ color: block.content.text.color }">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.60156 1.09961C1.94471 1.09961 0.601562 2.44275 0.601562 4.09961V22.4996C0.601562 24.1565 1.94471 25.4996 3.60156 25.4996H8V23.4996H3.60156C3.04928 23.4996 2.60156 23.0519 2.60156 22.4996V4.09961C2.60156 3.54732 3.04928 3.09961 3.60156 3.09961H18.8016C19.3538 3.09961 19.8016 3.54732 19.8016 4.09961V5.30078H21.8016V4.09961C21.8016 2.44276 20.4584 1.09961 18.8016 1.09961H3.60156Z" fill="currentColor"></path>
            <rect x="11.1992" y="8.5" width="19.2" height="22.4" rx="2" stroke="currentColor" stroke-width="2"></rect>
          </svg>
        </div>
      </div>
    </div>

    <!-- Divider Section -->
    <div class="dividerSection" v-if="block.type == 'divider'">
      <div
        class="divider"
        :style="{
          width: block.content.width + '%',
          height: block.content.height + 'px',
          'background-color': block.content.color
        }"
      ></div>
    </div>

    <!-- Custom HTML -->
    <template v-if="block.type == 'custom-html'">
      <div style="padding: 0px" align="center" v-html="block.content.html"></div>
    </template>

    <!-- Form Section -->
    <div
      class="formSection"
      v-if="block.type == 'form'"
      :style="{
        padding: block.content.margin.top + 'px ' + block.content.margin.right + 'px ' + block.content.margin.bottom + 'px ' + block.content.margin.left + 'px '
      }"
    >
      <!-- List Of Fields -->
      <div class="formItem" v-for="(field, fieldIndex) in block.content.fields" :key="fieldIndex" :style="{ margin: 'auto', width: block.content.input.width + '%' }">
        <div
          class="fieldLabel"
          v-if="block.content.label.visible"
          :style="{
            'font-size': block.content.label.fontSize + 'px',
            color: block.content.label.color
          }"
        >
          {{ field.labelText }}{{ field.required ? '*' : '' }}
        </div>
        <div clas="fieldInput">
          <!-- Form Styles -->
          <component :is="style">
            input:focus{ outline: none; border-color: {{ block.content.input.borderColor }} !important; border-width: {{ block.content.input.borderWidth + 1 + 'px' }} !important; } ::-webkit-input-placeholder { /* WebKit, Blink, Edge */ color: {{ block.content.input.placeholderColor }}; opacity: 1;} :-moz-placeholder { /* Mozilla Firefox 4 to 18 */ color: {{ block.content.input.placeholderColor }}; opacity: 1; } ::-moz-placeholder { /* Mozilla Firefox 19+ */ color: {{ block.content.input.placeholderColor }}; opacity: 1; } :-ms-input-placeholder { /* Internet Explorer 10-11 */ color: {{ block.content.input.placeholderColor }}; opacity: 1; } ::-ms-input-placeholder { /* Microsoft Edge */
            color: {{ block.content.input.placeholderColor }}; opacity: 1; } ::placeholder { /* Most modern browsers support this now. */ color:{{ block.content.input.placeholderColor }}; opacity: 1; }
          </component>

          <!-- Mobile Input -->
          <div
            class="mobileFormFieldContainer"
            :style="{
              color: block.content.input.textColor,
              height: block.content.input.height + 'px',
              'background-color': block.content.input.background,
              'border-color': block.content.input.borderColor,
              'border-width': block.content.input.borderWidth + 'px',
              'border-radius': block.content.input.borderRadius + 'px'
            }"
            v-if="field.columnName == 'mobile'"
          >
            <div
              class="countryFlagContainer"
              :style="{
                height: block.content.input.height + 'px',
                'font-size': block.content.input.textSize + 'px',
                padding: block.content.input.padding + 'px',
                'padding-top': block.content.input.padding + 'px',
                'background-color': block.content.input.background,
                border: 'none !important'
              }"
            >
              <span>+91</span>
              <svg :style="{ fill: block.content.input.textColor, 'margin-top': parseInt(block.content.input.textSize / 2) - 7 + 'px !important' }" class="dropdownArrow" height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" transform=""><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
            </div>
            <input
              class="grw-msg-form-input textInput mobileTextInput"
              :data-grw-prop="field.columnName"
              :data-grw-field="field.name"
              :data-grw-required="field.required"
              :placeholder="field.placeholder"
              type="text"
              :style="{
                color: block.content.input.textColor,
                height: block.content.input.height + 'px',
                'font-size': block.content.input.textSize + 'px',
                padding: block.content.input.padding + 'px',
                'background-color': block.content.input.background,
                border: 'none !important'
              }"
            />
          </div>

          <!-- Radio Button Input -->
          <template v-else-if="field.inputUiType == 'radioButton'">
            <div :class="{ 'pt-2': block.content.label.visible == false }">
              <div v-for="(optionItem, radioOptionItemIndex) in field.inputUiAllowedValues" :key="radioOptionItemIndex">
                <label style="margin-bottom: 3px !important" :style="{ '--formRadioButtonColor': block.content.input.radioButtonColor }">
                  <input class="radioInput" type="radio" name="age" :value="optionItem.value" />
                  <span :style="{ color: block.content.input.radioButtonTextColor }">{{ optionItem.label }}</span>
                </label>
              </div>
            </div>
          </template>
          <template v-else>
            <input
              class="grw-msg-form-input textInput"
              :data-grw-prop="field.columnName"
              :data-grw-field="field.name"
              :data-grw-required="field.required"
              :placeholder="field.placeholder"
              type="text"
              :style="{
                color: block.content.input.textColor,
                height: block.content.input.height + 'px',
                'font-size': block.content.input.textSize + 'px',
                padding: block.content.input.padding + 'px',
                'background-color': block.content.input.background,
                'border-color': block.content.input.borderColor,
                'border-width': block.content.input.borderWidth + 'px',
                'border-radius': block.content.input.borderRadius + 'px'
              }"
            />
          </template>
          <div class="grw-msg-form-error" :class="['grw-msg-form-error-' + field.columnName]"></div>
          <div :style="{ 'margin-bottom': (block.content.input.marginBottom ? block.content.input.marginBottom : 5) + 'px' }"></div>
        </div>
      </div>

      <!-- Submit Button -->
      <div
        class="submitButtonContainer"
        :style="{
          padding: block.content.button.padding.top + 'px ' + block.content.button.padding.right + 'px ' + block.content.button.padding.bottom + 'px ' + block.content.button.padding.left + 'px '
        }"
      >
        <button
          class="grw-msg-submit submitButton"
          :style="{
            color: block.content.button.textColor,
            background: block.content.button.buttonColor,
            padding: block.content.button.textPadding.top + 'px ' + block.content.button.textPadding.right + 'px ' + block.content.button.textPadding.bottom + 'px ' + block.content.button.textPadding.left + 'px ',
            'font-size': block.content.button.fontSize + 'px',
            'border-radius': block.content.button.radius + 'px',
            width: block.content.button.widthType == 'full' ? '100%' : 'auto',
            border: block.content.button.border.width + 'px solid ' + block.content.button.border.color,
            'font-weight': block.content.button.fontWeight ? block.content.button.fontWeight : 'normal',
            'font-family': block.content.button.fontFamily ? block.content.button.fontFamily : 'inherit',
            'letter-spacing': block.content.button.letterSpacing ? block.content.button.letterSpacing + 'px' : 'normal'
          }"
        >
          {{ block.content.button.text }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dragDropBlockSeperator',
  props: {
    block: {
      type: Object,
      required: true
    },
    isSubBlock: {
      type: Boolean,
      required: true
    }
  },
  components: {},
  data() {
    return {};
  },

  methods: {
    getCalculatedContainerStyles() {
      if (this.isSubBlock) return {};

      return {
        'background-color': this.block.content.rowBackgroundColor ? this.block.content.rowBackgroundColor : 'transparent',
        'background-image': this.block.content.rowBackgroundImage ? 'url(' + this.block.content.rowBackgroundImage + ')' : 'none',
        padding: this.block.content.rowPadding.top + 'px ' + this.block.content.rowPadding.right + 'px ' + this.block.content.rowPadding.bottom + 'px ' + this.block.content.rowPadding.left + 'px '
      };
    }
  },

  mounted() {}
};
</script>
