<template>
  <div class="webPopupEditor">
    <!-- Left Side -->
    <el-tabs v-model="selectedLeftSideTab" class="leftSide" type="border-card">
      <el-tab-pane class="settingsTab" label="Popup Settings" name="general">
        <el-collapse v-model="selectedLeftSitePopupScope" accordion>
          <!-- General Popup Options -->
          <el-collapse-item class="blockOptionsContainer" title="General Popup Settings" name="general">
            <el-row :gutter="20" class="blockOptionsContainer rowOptions">
              <!-- Widget Type -->
              <el-col :span="24" class="optionItem">
                <label class="label"> Widget Type </label>
                <div class="font-semibold text-black">
                  <span v-if="pupupAppereance.type == 'popup'">Popup</span>
                  <span v-if="pupupAppereance.type == 'flyout'">Flyout</span>
                  <span v-if="pupupAppereance.type == 'embed'">Embed</span>
                </div>
              </el-col>

              <!-- Widget Position - Only For Flyouts -->
              <template v-if="pupupAppereance.type == 'flyout'">
                <el-col :span="12" class="optionItem" v-if="pupupAppereance.type == 'flyout'">
                  <label class="label"> Flyout Desktop Position </label><br />
                  <div class="flyoutPositionSelector">
                    <div class="desktopSelector">
                      <div class="positionItem top left" @click="onFlyoutPositionChange('top', 'left')" :class="{ selected: pupupAppereance.flyoutPosition.x == 'left' && pupupAppereance.flyoutPosition.y == 'top' }"></div>
                      <div class="positionItem top center" @click="onFlyoutPositionChange('top', 'center')" :class="{ selected: pupupAppereance.flyoutPosition.x == 'center' && pupupAppereance.flyoutPosition.y == 'top' }"></div>
                      <div class="positionItem top right" @click="onFlyoutPositionChange('top', 'right')" :class="{ selected: pupupAppereance.flyoutPosition.x == 'right' && pupupAppereance.flyoutPosition.y == 'top' }"></div>
                      <div class="positionItem bottom left" @click="onFlyoutPositionChange('bottom', 'left')" :class="{ selected: pupupAppereance.flyoutPosition.x == 'left' && pupupAppereance.flyoutPosition.y == 'bottom' }"></div>
                      <div class="positionItem bottom center" @click="onFlyoutPositionChange('bottom', 'center')" :class="{ selected: pupupAppereance.flyoutPosition.x == 'center' && pupupAppereance.flyoutPosition.y == 'bottom' }"></div>
                      <div class="positionItem bottom right" @click="onFlyoutPositionChange('bottom', 'right')" :class="{ selected: pupupAppereance.flyoutPosition.x == 'right' && pupupAppereance.flyoutPosition.y == 'bottom' }"></div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12" class="optionItem">
                  <label class="label"> Flyout Mobile Position </label><br />
                  <div class="flyoutPositionSelector">
                    <div class="mobileSelector">
                      <div class="positionItem top" @click="pupupAppereance.flyoutPosition.y = 'top'" :class="{ selected: pupupAppereance.flyoutPosition.y == 'top' }"></div>
                      <div class="positionItem bottom" @click="pupupAppereance.flyoutPosition.y = 'bottom'" :class="{ selected: pupupAppereance.flyoutPosition.y == 'bottom' }"></div>
                    </div>
                  </div>
                </el-col>
              </template>

              <!-- Popup - Overlay Color -->
              <el-col :span="12" class="optionItem" v-if="pupupAppereance.type == 'popup'">
                <label class="label"> Popup Overlay Color </label>
                <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                  <div :style="{ background: pupupAppereance.overlayColor }">
                    <color-picker :color="pupupAppereance.overlayColor" theme="light" @changeColor="setBackgroundColorToProperty(pupupAppereance, 'overlayColor', ...arguments)" />
                  </div>
                  <div class="colorButton" slot="reference">
                    <span class="colorPreview" v-if="pupupAppereance.overlayColor" :style="{ 'background-color': pupupAppereance.overlayColor + ' !important', border: 'none', color: 'white !important' }"></span>
                    <span class="colorValue">{{ pupupAppereance.overlayColor ? pupupAppereance.overlayColor : 'Transparent' }}</span>
                  </div>
                </el-popover>
              </el-col>

              <!-- Close Button Color -->
              <el-col :span="12" class="optionItem" v-if="pupupAppereance.type != 'embed'">
                <label class="label"> Close Button Color </label>
                <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                  <div :style="{ background: pupupAppereance.closeButtonColor }">
                    <color-picker :color="pupupAppereance.closeButtonColor" theme="light" @changeColor="setBackgroundColorToProperty(pupupAppereance, 'closeButtonColor', ...arguments)" />
                  </div>
                  <div class="colorButton" slot="reference">
                    <span class="colorPreview" v-if="pupupAppereance.closeButtonColor" :style="{ 'background-color': pupupAppereance.closeButtonColor + ' !important', border: 'none', color: 'white !important' }"></span>
                    <span class="colorValue">{{ pupupAppereance.closeButtonColor ? pupupAppereance.closeButtonColor : 'Transparent' }}</span>
                  </div>
                </el-popover>
              </el-col>

              <!-- Popup Width -->
              <el-col :span="12" class="optionItem sliderContainer" v-if="pupupAppereance.type != 'embed'">
                <label class="label"> Width </label>
                <el-slider class="slider" :min="200" :step="50" :max="850" show-stops v-model="pupupAppereance.width"> </el-slider>
              </el-col>

              <!-- Popup Border Radius -->
              <el-col :span="12" class="optionItem sliderContainer">
                <label class="label"> Round Corners </label>
                <el-slider class="slider" :min="0" :max="22" v-model="pupupAppereance.borderRadius"> </el-slider>
              </el-col>

              <!-- App Embed Location -->
              <!-- <div class="klaviyo-form-YrTQQi"></div> -->
              <el-col :span="24" class="optionItem" v-if="pupupAppereance.type == 'embed'">
                <label class="label"> Add onto your site where you want this form to appear </label>
                <div class="border text-xs bg-gray-200 px-2 py-1.5 rounded">&lt;div class="grltcs-embed grltcs-embed-{{ webMessageId }}"&gt;&lt;/div&gt;</div>
              </el-col>
            </el-row>
          </el-collapse-item>
          <!-- Popup Background Options -->
          <el-collapse-item class="blockOptionsContainer" title=" Background Color/Image" name="background">
            <el-row :gutter="20" class="rowOptions">
              <!-- Row - Background Color -->
              <el-col :span="12" class="optionItem">
                <label class="label"> Row Background Color </label>
                <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                  <div :style="{ background: pupupAppereance.background.color }">
                    <color-picker :color="pupupAppereance.background.color" theme="light" @changeColor="setBackgroundColorToProperty(pupupAppereance.background, 'color', ...arguments)" />
                  </div>
                  <div class="colorButton" slot="reference">
                    <span class="colorPreview" v-if="pupupAppereance.background.color" :style="{ 'background-color': pupupAppereance.background.color + ' !important', border: 'none', color: 'white !important' }"></span>
                    <span class="colorValue">{{ pupupAppereance.background.color ? pupupAppereance.background.color : 'Transparent' }}</span>
                  </div>
                </el-popover>
              </el-col>
              <!-- Row- Image Selector -->
              <el-col :span="24" class="optionItem mt-2">
                <label class="label"> Popup Background Image </label>

                <!-- Upload Mode -->
                <div>
                  <el-radio-group v-model="pupupAppereance.background.imageSrcMode" size="mini">
                    <el-radio style="margin-right: 3px !important" label="upload" border>Upload Image</el-radio>
                    <el-radio label="url" border>Use Web URL</el-radio>
                  </el-radio-group>
                </div>

                <div class="imageSelector">
                  <!-- Image Upload Option -->
                  <template v-if="pupupAppereance.background.imageSrcMode == 'upload'">
                    <ImageManager @onSelect="onPopupImageUpload(pupupAppereance.background, 'image', ...arguments)" ref="popupImageManager"></ImageManager>
                    <el-image style="width: 100%; height: 150px" :src="pupupAppereance.background.image" fit="contain">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <el-row class="imageButtons" :gutter="10">
                      <el-col :span="pupupAppereance.background.image ? 12 : 24">
                        <el-button size="small" type="primary" plain @click="showImageuploader('popupImageManager')">
                          <template v-if="pupupAppereance.background.image">Change</template>
                          <template v-else>Select</template>
                          Image
                        </el-button>
                      </el-col>
                      <el-col :span="12" v-if="pupupAppereance.background.image">
                        <el-button size="small" type="danger" plain @click="pupupAppereance.background.image = null"> Remove Image </el-button>
                      </el-col>
                    </el-row>
                  </template>

                  <!-- Image URL Input -->
                  <div v-else style="position: relative; width: 100%">
                    <label class="label"> Image URL </label>
                    <el-input id="inputImageLink" v-model="pupupAppereance.background.image" type="text"></el-input>
                    <el-image class="mt-2" style="width: 100%; height: 150px" :src="pupupAppereance.background.image" fit="contain">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>

                  <el-row :gutter="10" style="margin-top: 10px">
                    <!-- Popup Image - Size -->
                    <el-col class="optionItem" :span="12" v-if="pupupAppereance.background.image">
                      <label class="label"> Background Image Size </label>
                      <div>
                        <el-select v-model="pupupAppereance.background.imageSize" size="small">
                          <el-option value="original" label="Original"></el-option>
                          <el-option value="cover" label="Cover"></el-option>
                          <el-option value="contain" label="Contain"></el-option>
                          <el-option value="100% 100%" label="Stretch"></el-option>
                        </el-select>
                      </div>
                    </el-col>
                    <!-- Popup Image - Alignment -->
                    <el-col :span="12" class="optionItem" v-if="pupupAppereance.background.image">
                      <label class="label"> Image Alignment </label>
                      <el-radio-group size="mini" class="radioButtonGroup" style="display: block" v-model="pupupAppereance.background.imageAlignment">
                        <el-radio-button label="0% 50%">
                          <i class="fa fa-align-left" aria-hidden="true"></i>
                        </el-radio-button>
                        <el-radio-button label="50% 50%">
                          <i class="fa fa-align-center" aria-hidden="true"></i>
                        </el-radio-button>
                        <el-radio-button label="100% 50%">
                          <i class="fa fa-align-right" aria-hidden="true"></i>
                        </el-radio-button>
                      </el-radio-group>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </el-collapse-item>

          <!-- Form Submit Action -->
          <el-collapse-item class="buttonOptions blockOptionsContainer" title="Form Submit Action" name="on-submit">
            <el-row :gutter="20">
              <!-- Submit Button Text -->
              <el-col :span="24">
                <label class="label"> Action to take when form submitted </label>
                <div>
                  <el-select v-model="pupupAppereance.formSubmit.action" style="width: 100%">
                    <el-option value="close" label="Close Popup/Flyout"></el-option>
                    <el-option value="thankyou" label="Show Thank-you Message"></el-option>
                    <el-option value="redirect" label="Redirect to URL"></el-option>
                  </el-select>
                </div>
              </el-col>

              <!-- Redirect URL If Specified -->
              <el-col :span="24" v-if="pupupAppereance.formSubmit.action == 'redirect'">
                <div class="label">Redirect Url on Form Sumittion</div>
                <el-input v-model="pupupAppereance.formSubmit.redirectUrl"></el-input>
              </el-col>
            </el-row>
          </el-collapse-item>

          <!-- Auto Set User Fields -->
          <el-collapse-item class="blockOptionsContainer customerAttriburesContainer" title="Customer Attributes" name="customer-attributes">
            <el-alert class="alertNote" :closable="false" type="warning"> Fields will set for the customers when they submit the form. </el-alert>

            <!-- Customer Fields List -->
            <div class="customerFieldListCotnainer" v-if="pupupAppereance.customerFields.length > 0">
              <el-row class="customerFieldItem" v-for="(field, fieldIndex) in pupupAppereance.customerFields" :key="fieldIndex">
                <el-col :span="18">
                  <div class="formFieldTitle">{{ field.key }}</div>
                  <div class="formFieldType">{{ field.value }}</div>
                </el-col>
                <el-col :span="6" style="text-align: right; padding-top: 10px">
                  <el-button type="danger" size="mini" icon="el-icon-delete" plain circle @click="pupupAppereance.customerFields.splice(fieldIndex, 1)"></el-button>
                </el-col>
              </el-row>
            </div>

            <!-- New Customer Attribute Form -->
            <div class="newInputContainer">
              <el-form ref="customerAttributesForm" label-position="top" :model="customerAttributesInput" label-width="120px" size="small" :rules="customerAttributeFormRules">
                <el-form-item label="Customer Attribute Name" prop="key">
                  <el-input v-model="customerAttributesInput.key" placeholder="Example: Subscribed Via Popup or Interested In Product"></el-input>
                </el-form-item>
                <el-form-item label="Customer Attribute Value" prop="value">
                  <el-input v-model="customerAttributesInput.value" placeholder="Example: Yes Or No"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button class="buttonContainer" type="primary" @click="onAddCustomerAttribute">Add Customer Field</el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-collapse-item>

          <!-- Custom CSS For Popup -->
          <el-collapse-item class="blockOptionsContainer" title="Custom CSS" name="custom-css">
            <el-alert class="py-1 leading-5 mb-3" :closable="false" type="warning"> CSS mentioned below Will be applied to popup. Make sure CSS structure is valid, invalid css may hamper entire popup's working. </el-alert>
            <el-input type="textarea" rows="4" v-model="pupupAppereance.customCss" placeholder="body { border: 1px solid red; }" />
          </el-collapse-item>

          <!-- Custom JS For Popup -->
          <el-collapse-item class="blockOptionsContainer" title="Custom Javascript" name="custom-js">
            <el-alert class="py-1 leading-5 mb-3" :closable="false" type="warning"> Javascript mentioned below Will be applied to popup. Please use javascript carefully. Unfavourable code may hamper entire popup's working. </el-alert>
            <el-input type="textarea" rows="4" v-model="pupupAppereance.customJs" placeholder="alert('this is custom script');" />
          </el-collapse-item>
        </el-collapse>
      </el-tab-pane>
      <el-tab-pane class="settingsTab" label="Settings" name="settings" v-if="selectedBlock">
        <el-collapse v-model="selectedLeftSiteSettingsScope" accordion>
          <!-- Row Options -->
          <el-collapse-item class="blockOptionsContainer" title="Row Settings" name="row" v-if="selectedRow">
            <el-row :gutter="20" class="rowOptions">
              <!-- Row- Image Selector -->
              <el-col :span="24" class="optionItem">
                <label class="label"> Row Background Image </label>
                <div class="imagePreview" v-if="selectedRow.content.rowBackgroundImage"></div>
                <div class="imageSelector">
                  <ImageManager @onSelect="onImageUpload(selectedRow, 'rowBackgroundImage', ...arguments)" ref="rowImageManager"></ImageManager>
                  <el-image style="width: 100%; height: 150px" :src="selectedRow.content.rowBackgroundImage" fit="contain">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <el-row class="imageButtons" :gutter="10">
                    <el-col :span="selectedRow.content.rowBackgroundImage ? 12 : 24">
                      <el-button size="small" type="primary" plain @click="showImageuploader('rowImageManager')">
                        <template v-if="selectedRow.content.rowBackgroundImage">Change</template>
                        <template v-else>Select</template>
                        Image
                      </el-button>
                    </el-col>
                    <el-col :span="12" v-if="selectedRow.content.rowBackgroundImage">
                      <el-button size="small" type="danger" plain @click="selectedRow.content.rowBackgroundImage = null"> Remove Image </el-button>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <!-- Row - Background Color -->
              <el-col :span="12" class="optionItem">
                <label class="label"> Row Background Color </label>

                <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                  <div :style="{ background: selectedRow.content.rowBackgroundColor }">
                    <color-picker :color="selectedRow.content.rowBackgroundColor" theme="light" @changeColor="setButtonSectionColor(selectedRow, 'rowBackgroundColor', ...arguments)" />
                  </div>
                  <div class="colorButton" slot="reference">
                    <span class="colorPreview" v-if="selectedRow.content.rowBackgroundColor" :style="{ 'background-color': selectedRow.content.rowBackgroundColor + ' !important', border: 'none', color: 'white !important' }"></span>
                    <span class="colorValue">{{ selectedRow.content.rowBackgroundColor ? selectedRow.content.rowBackgroundColor : 'Transparent' }}</span>
                  </div>
                </el-popover>
              </el-col>
              <!-- Row - Padding -->
              <el-col :span="24" class="optionItem paddingContainer">
                <label class="label"> Row Padding </label>
                <el-row :gutter="10">
                  <el-col :span="6">
                    <el-input-number controls-position="right" v-model="selectedRow.content.rowPadding.top" :min="0" :max="5000" size="mini"></el-input-number>
                    <label class="label bottom">Top</label>
                  </el-col>
                  <el-col :span="6">
                    <el-input-number controls-position="right" v-model="selectedRow.content.rowPadding.bottom" :min="0" :max="5000" size="mini"></el-input-number>
                    <label class="label bottom">Bottom</label>
                  </el-col>
                  <el-col :span="6">
                    <el-input-number controls-position="right" v-model="selectedRow.content.rowPadding.left" :min="0" :max="300" size="mini"></el-input-number>
                    <label class="label bottom">Left</label>
                  </el-col>
                  <el-col :span="6">
                    <el-input-number controls-position="right" v-model="selectedRow.content.rowPadding.right" :min="0" :max="300" size="mini"></el-input-number>
                    <label class="label bottom">Right</label>
                  </el-col>
                </el-row>
              </el-col>
              <!-- Row - Responsiveness -->
              <el-col :span="24">
                <div class="mt-4">Responsiveness</div>
                <el-row :gutter="20" class="rowOptions" style="margin-top: 10px" v-if="selectedRow.type.startsWith('columns')">
                  <el-col :span="12" class="optionItem">
                    <el-checkbox v-model="selectedRow.hideOnDesktop">Hide On Desktop</el-checkbox>
                  </el-col>
                  <el-col :span="12" class="optionItem">
                    <el-checkbox v-model="selectedRow.hideOnMobile">Hide On Mobile</el-checkbox>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-collapse-item>

          <!-- Block Options -->
          <el-collapse-item class="blockOptionsContainer" v-if="selectedBlock.type != 'form' && selectedBlock.type != 'coupon-code' && selectedBlock.type != 'button' && !selectedBlock.type.startsWith('columns')" :title="selectedBlock.name + ' Settings'" name="block">
            <!-- Image Options -->
            <div class="imageOptions" v-if="selectedBlock.type == 'image'">
              <el-row :gutter="20">
                <!-- Image Selector -->
                <el-col class="optionItem mb-2" :span="24">
                  <!-- Upload Mode -->
                  <div>
                    <el-radio-group v-model="selectedBlock.content.srcMode" size="mini">
                      <el-radio style="margin-right: 3px !important" label="upload" border>Upload Image</el-radio>
                      <el-radio label="url" border>Use Web URL</el-radio>
                    </el-radio-group>
                  </div>
                  <div v-if="!selectedBlock.content.srcMode || selectedBlock.content.srcMode == 'upload'" class="imageSelector">
                    <ImageManager @onSelect="onImageUpload(selectedBlock, 'src', ...arguments)" ref="blockImageManager"></ImageManager>
                    <el-image style="width: 100%; height: 150px" :src="selectedBlock.content.src" fit="contain">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                    <el-button size="small" type="info" plain @click="showImageuploader('blockImageManager')">
                      <template v-if="selectedBlock.content.src">Change</template>
                      <template v-else>Select</template>
                      Image
                    </el-button>
                  </div>
                  <!-- Image URL Input -->
                  <div v-else style="position: relative; width: 100%" class="imageSelector">
                    <label class="label"> Image URL </label>
                    <el-input id="inputImageLink" v-model="selectedBlock.content.src" type="text"></el-input>
                    <el-image class="mt-2" style="width: 100%; height: 150px" :src="selectedBlock.content.src" fit="contain">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                </el-col>
                <!-- Image - Click Url -->
                <el-col :span="24" class="optionItem" style="position: relative">
                  <label class="label"> Click Link [Opened when clicked] </label>
                  <el-input id="inputImageClickLink" v-model="selectedBlock.content.link" type="text"></el-input>
                </el-col>
                <!-- Image - Alignment -->
                <el-col :span="12" class="optionItem radioButtonContainer">
                  <label class="label"> Alignment </label>
                  <el-radio-group size="mini" class="radioButtonGroup" style="display: block" v-model="selectedBlock.content.alignment">
                    <el-radio-button label="left">
                      <i class="fa fa-align-left" aria-hidden="true"></i>
                    </el-radio-button>
                    <el-radio-button label="center">
                      <i class="fa fa-align-center" aria-hidden="true"></i>
                    </el-radio-button>
                    <el-radio-button label="right">
                      <i class="fa fa-align-right" aria-hidden="true"></i>
                    </el-radio-button>
                  </el-radio-group>
                </el-col>
                <!-- Image - Width -->
                <el-col :span="12" class="optionItem sliderContainer">
                  <label class="label"> Image width </label>
                  <el-slider class="slider" :min="5" :max="100" v-model="selectedBlock.content.width"> </el-slider>
                </el-col>
                <!-- Image - Border Radius -->
                <el-col :span="24" class="optionItem sliderContainer">
                  <label class="label"> Image Radius </label>
                  <el-slider class="slider" :min="0" :max="500" v-model="selectedBlock.content.radius"> </el-slider>
                </el-col>
                <!-- Image - Margin -->
                <el-col :span="24" class="optionItem paddingContainer">
                  <label class="label"> Image Padding </label>
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.top" :min="0" :max="5000" size="mini"></el-input-number>
                      <label class="label bottom">Top</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.bottom" :min="0" :max="5000" size="mini"></el-input-number>
                      <label class="label bottom">Bottom</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.left" :min="0" :max="300" size="mini"></el-input-number>
                      <label class="label bottom">Left</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.right" :min="0" :max="300" size="mini"></el-input-number>
                      <label class="label bottom">Right</label>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>

            <!-- Text Options -->
            <div class="textOptions blockOptionsContainer" v-if="selectedBlock.type == 'text'">
              <!-- Ck Editor -->
              <div class="ckEditorContainer mb-4">
                <MceTextEditor ref="mceEditor" :height="400" @onBlockEditorHtmlChange="onBlockEditorHtmlChange" :inputValue="selectedBlock.content.html" />
              </div>
              <el-row :gutter="20">
                <!-- Button Margin -->
                <el-col :span="24" class="optionItem paddingContainer">
                  <label class="label"> Section Padding </label>
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.top" :min="0" :max="5000" size="mini"></el-input-number>
                      <label class="label bottom">Top</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.bottom" :min="0" :max="5000" size="mini"></el-input-number>
                      <label class="label bottom">Bottom</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.left" :min="0" :max="300" size="mini"></el-input-number>
                      <label class="label bottom">Left</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.right" :min="0" :max="300" size="mini"></el-input-number>
                      <label class="label bottom">Right</label>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>

            <!-- Divider Options -->
            <div class="buttonOptions blockOptionsContainer" v-if="selectedBlock.type == 'divider'">
              <el-row :gutter="20">
                <!-- Divider Height -->
                <el-col :span="24">
                  <label class="label"> Divider Height </label>
                  <el-slider :min="1" :max="100" v-model="selectedBlock.content.height"> </el-slider>
                </el-col>
                <!-- Divider Height -->
                <el-col :span="24">
                  <label class="label"> Divider Width </label>
                  <el-slider :min="10" :max="100" v-model="selectedBlock.content.width"> </el-slider>
                </el-col>
                <!-- Divider Color -->
                <el-col :span="12">
                  <label class="label"> Divider Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.color }">
                      <color-picker :color="selectedBlock.content.color" theme="light" @changeColor="setDividerColor" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.color + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.color }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Block Padding -->
                <el-col :span="24" class="paddingContainer">
                  <label class="label"> Block Padding</label>
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.rowPadding.top" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Top</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.rowPadding.bottom" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Bottom</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.rowPadding.left" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Left</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.rowPadding.right" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Right</label>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>

            <!-- Custom HTML Options -->
            <div class="buttonOptions blockOptionsContainer" v-if="selectedBlock.type == 'custom-html'">
              <el-row :gutter="20" class="rowOptions">
                <el-col :col="24" class="py-1">
                  <label class="label"> Enter Custom HTML </label>
                  <el-input type="textarea" v-model="selectedBlock.content.html" :rows="8"> </el-input>
                  <div class="text-gray-400 text-xs mt-2">Use this block carefully, incostintent html content may break entire webpage structure.</div>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>

          <!-- Button Options -->
          <template v-if="selectedBlock.type == 'button'">
            <el-collapse-item class="buttonOptions blockOptionsContainer" title="Button Settings" name="button">
              <el-row :gutter="20">
                <!-- Button Text -->
                <el-col :span="24">
                  <label class="label"> Button Text </label>
                  <el-input v-model="selectedBlock.content.text" type="text"></el-input>
                </el-col>
                <!-- Click Url -->
                <el-col :span="24" style="position: relative">
                  <label class="label"> Click Link [Opened when clicked] </label>
                  <el-input id="inputButtonClickLink" v-model="selectedBlock.content.link" type="text"></el-input>
                </el-col>
                <el-col :span="24" style="margin-top: 15px">
                  <el-checkbox v-model="selectedBlock.convertOnClick">Mark user converted when button clicked</el-checkbox>
                </el-col>
                <el-col :span="24">
                  <el-checkbox v-model="selectedBlock.closeOnClick">Close popup when button clicked</el-checkbox>
                </el-col>
              </el-row>
            </el-collapse-item>

            <!-- Button Theme Options -->
            <el-collapse-item class="buttonOptions blockOptionsContainer" title="Button Theme Options" name="button-theme">
              <el-row :gutter="20">
                <!-- Button Colors -->
                <el-col :span="12">
                  <label class="label"> Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.textColor }">
                      <color-picker :color="selectedBlock.content.textColor" theme="light" @changeColor="setButtonTextColor" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.textColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.textColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <el-col :span="12">
                  <label class="label"> Button Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.buttonColor }">
                      <color-picker :color="selectedBlock.content.buttonColor" theme="light" @changeColor="setButtonColor" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.buttonColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.buttonColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Font Size -->
                <el-col :span="24">
                  <label class="label"> Text Size </label>
                  <el-slider :min="12" :max="70" v-model="selectedBlock.content.fontSize"> </el-slider>
                </el-col>
                <!-- Alignment -->
                <el-col :span="12" class="alignmentButton">
                  <label class="label"> Alignment </label>
                  <el-radio-group style="display: block; width: 100%" v-model="selectedBlock.content.alignment">
                    <el-radio-button label="left">
                      <i class="fa fa-align-left" aria-hidden="true"></i>
                    </el-radio-button>
                    <el-radio-button label="center">
                      <i class="fa fa-align-center" aria-hidden="true"></i>
                    </el-radio-button>
                    <el-radio-button label="right">
                      <i class="fa fa-align-right" aria-hidden="true"></i>
                    </el-radio-button>
                  </el-radio-group>
                </el-col>
                <!-- Border Radius -->
                <el-col :span="12" class="sliderContainer">
                  <label class="label"> Button Radius </label>
                  <el-slider class="slider" :min="0" :max="50" v-model="selectedBlock.content.radius"> </el-slider>
                </el-col>
                <!-- Button - Text Padding -->
                <el-col :span="24" class="paddingContainer">
                  <label class="label"> Text Padding</label>
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.insidePadding.top" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Top</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.insidePadding.bottom" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Bottom</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.insidePadding.left" :min="0" :max="400" size="mini"></el-input-number>
                      <label class="label bottom">Left</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.insidePadding.right" :min="0" :max="400" size="mini"></el-input-number>
                      <label class="label bottom">Right</label>
                    </el-col>
                  </el-row>
                </el-col>
                <!-- Button Margin -->
                <el-col :span="24" class="optionItem paddingContainer">
                  <label class="label"> Button Padding </label>
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.top" :min="0" :max="5000" size="mini"></el-input-number>
                      <label class="label bottom">Top</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.bottom" :min="0" :max="5000" size="mini"></el-input-number>
                      <label class="label bottom">Bottom</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.left" :min="0" :max="300" size="mini"></el-input-number>
                      <label class="label bottom">Left</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.right" :min="0" :max="300" size="mini"></el-input-number>
                      <label class="label bottom">Right</label>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-collapse-item>
          </template>

          <!-- Coupon Code Block Options -->
          <template v-if="selectedBlock.type == 'coupon-code'">
            <el-collapse-item class="buttonOptions blockOptionsContainer" title="Coupon Text" name="block">
              <el-row :gutter="20">
                <!-- Coupon Text -->
                <el-col :span="8">
                  <label class="label"> Coupon Text </label>
                  <el-input v-model="selectedBlock.content.text.text" type="text"></el-input>
                </el-col>
                <el-col :span="8">
                  <label class="label"> Font Family </label>
                  <el-select v-model="selectedBlock.content.text.fontFamily" style="width: 100%" filterable>
                    <el-option-group v-for="group in fontsList" :key="group.label" :label="group.label">
                      <el-option v-for="(font, index) in group.options" :key="index" :label="font.label" :value="font.value">
                        <span :style="{ 'font-family': font.value }">{{ font.label }}</span>
                      </el-option>
                    </el-option-group>
                  </el-select>
                </el-col>
                <!-- Text Color -->
                <el-col :span="8">
                  <label class="label"> Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.text.color }">
                      <color-picker :color="selectedBlock.content.text.color" theme="light" @changeColor="setColor(selectedBlock.content.text, 'color', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.text.color + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.text.color }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Text Font Size -->
                <el-col :span="8">
                  <label class="label"> Text Size </label>
                  <el-input-number class="mt-1" style="width: 100% !important; overlfow: hidden" v-model="selectedBlock.content.text.fontSize" controls-position="right" :min="12" :max="72"></el-input-number>
                </el-col>
                <!-- Text Font Weight -->
                <el-col :span="8">
                  <label class="label"> Font Weight </label>
                  <el-select class="mt-1" v-model="selectedBlock.content.text.fontWeight">
                    <el-option label="Light" value="lighter"></el-option>
                    <el-option label="Normal" value="normal"></el-option>
                    <el-option label="Bold" value="bold"></el-option>
                  </el-select>
                </el-col>
                <!-- Letter Spacing -->
                <el-col :span="8">
                  <label class="label"> Letter Spacing </label>
                  <el-input-number class="mt-0" style="width: 100% !important; overlfow: hidden" v-model="selectedBlock.content.text.letterSpacing" controls-position="right" :min="1" :max="10"></el-input-number>
                </el-col>
              </el-row>
              <!-- Text Alignment -->
              <el-col :span="8" class="alignmentButton">
                <label class="label"> Text Alignment </label>
                <el-select class="mt-1" v-model="selectedBlock.content.text.alignment" style="width: 100% !important; overlfow: hidden">
                  <el-option label="Left" value="left"></el-option>
                  <el-option label="Center" value="center"></el-option>
                  <el-option label="Right" value="right"></el-option>
                </el-select>
              </el-col>
            </el-collapse-item>

            <el-collapse-item class="buttonOptions blockOptionsContainer" title="Coupon Background & Border" name="coupon-background-border">
              <el-row :gutter="20">
                <!-- Background Color -->
                <el-col :span="8">
                  <label class="label"> Background Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.backgroundColor }">
                      <color-picker :color="selectedBlock.content.backgroundColor" theme="light" @changeColor="setColor(selectedBlock.content, 'backgroundColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.backgroundColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.backgroundColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Border Color -->
                <el-col :span="8">
                  <label class="label"> Border Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.border.color }">
                      <color-picker :color="selectedBlock.content.border.color" theme="light" @changeColor="setColor(selectedBlock.content.border, 'color', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.border.color + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.border.color }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Border Thickness -->
                <el-col :span="8">
                  <label class="label"> Border Thickness </label>
                  <el-input-number class="mt-0" style="width: 100% !important; overlfow: hidden" v-model="selectedBlock.content.border.width" controls-position="right" :min="0"></el-input-number>
                </el-col>
              </el-row>

              <el-row :gutter="20">
                <!-- Border Style -->
                <el-col :span="8">
                  <label class="label"> Border Style </label>
                  <el-select class="mt-0" v-model="selectedBlock.content.border.type">
                    <el-option label="Dashed" value="dashed"></el-option>
                    <el-option label="Dotted" value="dotted"></el-option>
                    <el-option label="Solid" value="solid"></el-option>
                  </el-select>
                </el-col>

                <!-- Border Radius -->
                <el-col :span="8">
                  <label class="label"> Corner Radius </label>
                  <el-input-number class="mt-0" style="width: 100% !important; overlfow: hidden" v-model="selectedBlock.content.radius" controls-position="right" :min="0"></el-input-number>
                </el-col>

                <!-- Button - Text Padding -->
                <el-col :span="24" class="paddingContainer mt-3">
                  <label class="label"> Coupon Box Inner Padding</label>
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.insidePadding.top" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Top</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.insidePadding.bottom" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Bottom</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.insidePadding.left" :min="0" :max="400" size="mini"></el-input-number>
                      <label class="label bottom">Left</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.insidePadding.right" :min="0" :max="400" size="mini"></el-input-number>
                      <label class="label bottom">Right</label>
                    </el-col>
                  </el-row>
                </el-col>

                <!-- Button Margin -->
                <el-col :span="24" class="paddingContainer">
                  <label class="label"> Coupon Box Margin </label>
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.top" :min="0" :max="5000" size="mini"></el-input-number>
                      <label class="label bottom">Top</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.bottom" :min="0" :max="5000" size="mini"></el-input-number>
                      <label class="label bottom">Bottom</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.left" :min="0" :max="300" size="mini"></el-input-number>
                      <label class="label bottom">Left</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.right" :min="0" :max="300" size="mini"></el-input-number>
                      <label class="label bottom">Right</label>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-collapse-item>
          </template>

          <!-- Form Options -->
          <template v-if="selectedBlock.type == 'form'">
            <!-- Form Fields -->
            <el-collapse-item class="buttonOptions blockOptionsContainer" title="Form Fields" name="fields">
              <div class="formFieldContainer">
                <div class="formFieldHeader">
                  <span class="headerTitle">Form Fields</span>
                  <!-- Add Field Dropdown -->
                  <el-dropdown class="addFieldButton" size="small" @command="onAddFormField">
                    <el-button size="mini" type="primary"> Add Form Field<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command="prop.columnName" :divided="prop.isDivided" v-for="(prop, index) in customerFieldList" :key="index">{{ prop.name }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>

                <!-- Field List -->
                <div class="formFieldList">
                  <!-- Block List - Iterate -->
                  <draggable v-model="selectedBlock.content.fields" group="formFieldList" @start="drag = true" @end="drag = false" v-bind="dragOptions" handle=".fieldDragContainer">
                    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                      <el-row class="formField" v-for="(field, fieldIndex) in selectedBlock.content.fields" :key="fieldIndex">
                        <el-col :span="2">
                          <i class="fa fa-exchange fa-rotate-90 fieldDragContainer moveArrow" aria-hidden="true"></i>
                        </el-col>
                        <el-col :span="16">
                          <div class="formFieldTitle">{{ field.name }}</div>
                          <div class="formFieldType">{{ field.name }} Type, {{ field.required ? 'Required' : 'Not Required' }}</div>
                        </el-col>
                        <el-col :span="6" style="text-align: right; padding-top: 10px">
                          <el-button type="info" plain size="mini" icon="el-icon-arrow-up" v-if="field.editMode" circle @click="field.editMode = false"></el-button>
                          <el-button type="info" plain size="mini" icon="el-icon-arrow-down" v-else circle @click="field.editMode = true"></el-button>

                          <el-button type="danger" size="mini" icon="el-icon-delete" plain circle :disabled="selectedBlock.content.fields.length == 1" @click="selectedBlock.content.fields.splice(fieldIndex, 1)"></el-button>
                        </el-col>

                        <el-col :span="24" class="editFieldForm" v-if="field.editMode">
                          <el-form ref="form" label-position="top" :model="field" label-width="120px" size="small">
                            <el-row :gutter="15">
                              <el-col :span="12">
                                <el-form-item label="Label">
                                  <el-input v-model="field.labelText"></el-input>
                                </el-form-item>
                              </el-col>
                              <el-col :span="12">
                                <el-form-item label="Placeholder Text">
                                  <el-input v-model="field.placeholder"></el-input>
                                </el-form-item>
                              </el-col>
                              <el-col :span="24">
                                <el-form-item label="Input Type">
                                  <el-select v-model="field.inputUiType" class="w-full">
                                    <el-option label="Normal Input" value="input"></el-option>
                                    <!-- <el-option label="Dropdown" value="dropdown"></el-option> -->
                                    <el-option label="Radio Buttons" value="radioButton"></el-option>
                                    <el-option label="Date" value="datetime"></el-option>
                                  </el-select>
                                </el-form-item>
                              </el-col>
                              <el-col :span="24" v-if="field.inputUiType == 'radioButton'">
                                <div class="border rounded-md overflow-hidden mt-1">
                                  <div class="px-2 mb-2 py-1 border-b bg-gray-100">Allowed Field Values</div>
                                  <div class="mb-2">
                                    <div class="px-2 pt-1.5" v-for="(allowedItem, inputUiAllowedValuesIndex) in field.inputUiAllowedValues" :key="inputUiAllowedValuesIndex">
                                      <el-row :gutter="10">
                                        <el-col :span="12">
                                          <el-form-item label="Display Text">
                                            <el-input v-model="allowedItem.label"></el-input>
                                          </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                          <el-form-item label="Value">
                                            <el-input v-model="allowedItem.value"></el-input>
                                          </el-form-item>
                                        </el-col>
                                      </el-row>
                                    </div>
                                  </div>

                                  <div class="px-2 pb-2">
                                    <el-button size="mini" type="primary" @click="field.inputUiAllowedValues.push({ label: '', value: '' })" plain> Add Allowed Value </el-button>
                                  </div>
                                </div>
                              </el-col>
                              <el-col :span="24" v-else-if="field.inputUiType == 'datetime'">
                                <div class="mb-2">
                                  <div>
                                    <div>DateFormat</div>
                                    <div class="flex">
                                      <el-select v-model="field.inputDateTypeMeta.format" class="flex-1 mr-2">
                                        <el-option label="DD MM YYYY" value="ddmmyyyy"></el-option>
                                        <el-option label="MM DD YYYY" value="mmddyyyy"></el-option>
                                      </el-select>
                                      <el-select v-model="field.inputDateTypeMeta.seperator" class="w-20">
                                        <el-option label="/" value="/"></el-option>
                                        <el-option label="-" value="-"></el-option>
                                      </el-select>
                                    </div>
                                  </div>
                                </div>
                              </el-col>
                              <el-col :span="24">
                                <el-form-item style="padding-top: 10px; padding-left: 2px">
                                  <el-checkbox v-model="field.required">Required Field</el-checkbox>
                                </el-form-item>
                              </el-col>
                            </el-row>
                          </el-form>
                        </el-col>
                      </el-row>
                    </transition-group>
                  </draggable>
                </div>
              </div>
            </el-collapse-item>
            <!-- Form Submit Button -->
            <el-collapse-item class="buttonOptions blockOptionsContainer" title="Submit Button Style" name="button">
              <el-row :gutter="20">
                <!-- Submit Button Text -->
                <el-col :span="8">
                  <label class="label"> Button Text </label>
                  <el-input v-model="selectedBlock.content.button.text" type="text"></el-input>
                </el-col>
                <!-- Text Color -->
                <el-col :span="8">
                  <label class="label"> Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.button.textColor }">
                      <color-picker :color="selectedBlock.content.button.textColor" theme="light" @changeColor="setColor(selectedBlock.content.button, 'textColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.button.textColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.button.textColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Button Color -->
                <el-col :span="8">
                  <label class="label"> Background Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.button.buttonColor }">
                      <color-picker :color="selectedBlock.content.button.buttonColor" theme="light" @changeColor="setColor(selectedBlock.content.button, 'buttonColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.button.buttonColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.button.buttonColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Font Family -->
                <el-col :span="6" class="pt-1">
                  <label class="label"> Font Family </label>
                  <el-select size="mini" v-model="selectedBlock.content.button.fontFamily" style="width: 100%" filterable>
                    <el-option-group v-for="group in fontsList" :key="group.label" :label="group.label">
                      <el-option v-for="(font, index) in group.options" :key="index" :label="font.label" :value="font.value">
                        <span :style="{ 'font-family': font.value }">{{ font.label }}</span>
                      </el-option>
                    </el-option-group>
                  </el-select>
                </el-col>
                <!-- Text Font Size -->
                <el-col :span="6" class="pt-1">
                  <label class="label"> Font Size </label>
                  <el-input-number size="mini" class="mt-0" style="width: 100% !important; overlfow: hidden" v-model="selectedBlock.content.button.fontSize" controls-position="right" :min="12" :max="72"></el-input-number>
                </el-col>
                <!-- Text Font Weight -->
                <el-col :span="6" class="pt-1">
                  <label class="label"> Font Weight </label>
                  <el-select size="mini" class="mt-0" v-model="selectedBlock.content.button.fontWeight">
                    <el-option label="Light" value="lighter"></el-option>
                    <el-option label="Normal" value="normal"></el-option>
                    <el-option label="Bold" value="bold"></el-option>
                  </el-select>
                </el-col>
                <!-- Letter Spacing -->
                <el-col :span="6" class="pt-1">
                  <label class="label"> Letter Spacing </label>
                  <el-input-number size="mini" class="mt-0" style="width: 100% !important; overlfow: hidden" v-model="selectedBlock.content.button.letterSpacing" controls-position="right" :min="1" :max="10"></el-input-number>
                </el-col>
                <!-- Submit Button Width -->
                <!-- <el-col :span="24" class="optionItem">
                  <label class="label"> Submit Button Width </label>
                  <el-radio-group size="mini" class="radioButtonGroup" style="display: block" v-model="selectedBlock.content.button.widthType">
                    <el-radio-button label="full"> Full Width </el-radio-button>
                    <el-radio-button label="auto"> Wrap Text With Padding </el-radio-button>
                  </el-radio-group>
                </el-col> -->

                <!-- Button - Text Padding -->
                <el-col :span="24" class="paddingContainer">
                  <label class="label"> Text Padding</label>
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.textPadding.top" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Top</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.textPadding.bottom" :min="0" :max="100" size="mini"></el-input-number>
                      <label class="label bottom">Bottom</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.textPadding.left" :min="0" :max="400" size="mini"></el-input-number>
                      <label class="label bottom">Left</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.textPadding.right" :min="0" :max="400" size="mini"></el-input-number>
                      <label class="label bottom">Right</label>
                    </el-col>
                  </el-row>
                </el-col>
                <!-- Button Margin -->
                <el-col :span="24" class="optionItem paddingContainer">
                  <label class="label"> Button Padding </label>
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.padding.top" :min="0" :max="5000" size="mini"></el-input-number>
                      <label class="label bottom">Top</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.padding.bottom" :min="0" :max="5000" size="mini"></el-input-number>
                      <label class="label bottom">Bottom</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.padding.left" :min="0" :max="300" size="mini"></el-input-number>
                      <label class="label bottom">Left</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.button.padding.right" :min="0" :max="300" size="mini"></el-input-number>
                      <label class="label bottom">Right</label>
                    </el-col>
                  </el-row>
                </el-col>
                <!-- Border Width -->
                <el-col :span="8" class="pt-1">
                  <label class="label"> Border Width </label>
                  <el-input-number class="mt-0" style="width: 100% !important; overlfow: hidden" v-model="selectedBlock.content.button.border.width" controls-position="right" :min="1" :max="50"></el-input-number>
                </el-col>
                <!-- Border Color -->
                <el-col :span="8">
                  <label class="label"> Button Border Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.button.border.color }">
                      <color-picker :color="selectedBlock.content.button.border.color" theme="light" @changeColor="setColor(selectedBlock.content.button.border, 'color', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.button.border.color + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.button.border.color }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Border Radius -->
                <el-col :span="8">
                  <label class="label"> Button Radius </label>
                  <el-input-number class="mt-0" style="width: 100% !important; overlfow: hidden" v-model="selectedBlock.content.button.radius" controls-position="right" :min="0" :max="50000"></el-input-number>
                </el-col>
              </el-row>
            </el-collapse-item>
            <!-- Form Label Settings -->
            <el-collapse-item class="buttonOptions blockOptionsContainer" title="Form Label" name="labels">
              <el-row :gutter="20">
                <!-- Label Color -->
                <el-col :span="12" class="optionItem sliderContainer">
                  <label class="label"> Label Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.label.color }">
                      <color-picker :color="selectedBlock.content.label.color" theme="light" @changeColor="setBackgroundColorToProperty(selectedBlock.content.label, 'color', ...arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" v-if="selectedBlock.content.label.color" :style="{ 'background-color': selectedBlock.content.label.color + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.label.color ? selectedBlock.content.label.color : 'Transparent' }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Form Label Visible -->
                <el-col :span="12" class="optionItem sliderContainer">
                  <label class="label"> Show Form Labels </label>
                  <div style="padding-top: 2px">
                    <el-radio-group size="medium" v-model="selectedBlock.content.label.visible">
                      <el-radio-button :label="true">Yes</el-radio-button>
                      <el-radio-button :label="false">No</el-radio-button>
                    </el-radio-group>
                  </div>
                </el-col>
                <el-col :span="12" class="optionItem sliderContainer">
                  <label class="label"> Label Font Size </label>
                  <el-slider class="slider" :min="9" :max="18" v-model="selectedBlock.content.label.fontSize"> </el-slider>
                </el-col>
              </el-row>
            </el-collapse-item>
            <!-- Form Input Settings -->
            <el-collapse-item class="buttonOptions blockOptionsContainer" title="Form Input" name="inputs">
              <el-row :gutter="20">
                <!-- Input Width -->
                <el-col :span="12" class="optionItem sliderContainer">
                  <label class="label" style="width: 100%">
                    Input Width
                    <span style="float: right">{{ selectedBlock.content.input.width }}%</span>
                  </label>
                  <el-slider class="slider" :min="30" :max="100" v-model="selectedBlock.content.input.width"> </el-slider>
                </el-col>
                <!-- Input Height -->
                <el-col :span="12" class="optionItem sliderContainer">
                  <label class="label" style="width: 100%">
                    Input Height
                    <span style="float: right">{{ selectedBlock.content.input.height }}px</span>
                  </label>
                  <el-slider class="slider" :min="30" :max="100" v-model="selectedBlock.content.input.height"> </el-slider>
                </el-col>
                <!-- Input Text Size -->
                <el-col :span="12" class="optionItem sliderContainer">
                  <label class="label" style="width: 100%">
                    Input Text Size
                    <span style="float: right">{{ selectedBlock.content.input.textSize }}px</span>
                  </label>
                  <el-slider class="slider" :min="8" :max="40" v-model="selectedBlock.content.input.textSize"> </el-slider>
                </el-col>
                <!-- Input Text Padding -->
                <el-col :span="12" class="optionItem sliderContainer">
                  <label class="label" style="width: 100%">
                    Input Text Padding
                    <span style="float: right">{{ selectedBlock.content.input.padding }}px</span>
                  </label>
                  <el-slider class="slider" :min="5" :max="50" v-model="selectedBlock.content.input.padding"> </el-slider>
                </el-col>
                <!-- Input Text Color -->
                <el-col :span="12">
                  <label class="label"> Input Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.input.textColor }">
                      <color-picker :color="selectedBlock.content.input.textColor" theme="light" @changeColor="setColor(selectedBlock.content.input, 'textColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.input.textColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.input.textColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Input Placeholder Color -->
                <el-col :span="12">
                  <label class="label"> Input Placeholder Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.input.placeholderColor }">
                      <color-picker :color="selectedBlock.content.input.placeholderColor" theme="light" @changeColor="setColor(selectedBlock.content.input, 'placeholderColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.input.placeholderColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.input.placeholderColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Input Background Color -->
                <el-col :span="12">
                  <label class="label"> Input Background Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.input.background }">
                      <color-picker :color="selectedBlock.content.input.background" theme="light" @changeColor="setColor(selectedBlock.content.input, 'background', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.input.background + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.input.background }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Input Border Color -->
                <el-col :span="12">
                  <label class="label"> Input Border Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.input.borderColor }">
                      <color-picker :color="selectedBlock.content.input.borderColor" theme="light" @changeColor="setColor(selectedBlock.content.input, 'borderColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.input.borderColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.input.borderColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Radio Button Color -->
                <el-col :span="12">
                  <label class="label"> Radio Button Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.input.radioButtonColor }">
                      <color-picker :color="selectedBlock.content.input.radioButtonColor" theme="light" @changeColor="setColor(selectedBlock.content.input, 'radioButtonColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.input.radioButtonColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.input.radioButtonColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Radio Button Text Color -->
                <el-col :span="12">
                  <label class="label"> Radio Button Text Color </label>
                  <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                    <div :style="{ background: selectedBlock.content.input.radioButtonTextColor }">
                      <color-picker :color="selectedBlock.content.input.radioButtonTextColor" theme="light" @changeColor="setColor(selectedBlock.content.input, 'radioButtonTextColor', arguments)" />
                    </div>
                    <div class="colorButton" slot="reference">
                      <span class="colorPreview" :style="{ 'background-color': selectedBlock.content.input.radioButtonTextColor + ' !important', border: 'none', color: 'white !important' }"></span>
                      <span class="colorValue">{{ selectedBlock.content.input.radioButtonTextColor }}</span>
                    </div>
                  </el-popover>
                </el-col>
                <!-- Input Boder Thickness -->
                <el-col :span="12" class="optionItem sliderContainer">
                  <label class="label" style="width: 100%">
                    Boder Thickness
                    <span style="float: right">{{ selectedBlock.content.input.borderWidth }}px</span>
                  </label>
                  <el-slider class="slider" :min="0" :max="10" v-model="selectedBlock.content.input.borderWidth"> </el-slider>
                </el-col>
                <!-- Input Corner Rounding -->
                <el-col :span="12" class="optionItem sliderContainer">
                  <label class="label" style="width: 100%">
                    Corner Rounding
                    <span style="float: right">{{ selectedBlock.content.input.borderRadius }}px</span>
                  </label>
                  <el-slider class="slider" :min="0" :max="50" v-model="selectedBlock.content.input.borderRadius"> </el-slider>
                </el-col>
                <!-- Input Space Below -->
                <el-col :span="12" class="optionItem sliderContainer">
                  <label class="label" style="width: 100%">
                    Space Below Input Box
                    <span style="float: right">{{ selectedBlock.content.input.marginBottom }}px</span>
                  </label>
                  <el-slider class="slider" :min="0" :max="30" v-model="selectedBlock.content.input.marginBottom"> </el-slider>
                </el-col>
              </el-row>
            </el-collapse-item>
            <!-- Block Settings -->
            <el-collapse-item class="buttonOptions blockOptionsContainer" title="Block Settings" name="block">
              <el-row :gutter="20">
                <el-col :span="24" class="optionItem paddingContainer">
                  <label class="label"> Section Padding </label>
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.top" :min="0" :max="5000" size="mini"></el-input-number>
                      <label class="label bottom">Top</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.bottom" :min="0" :max="5000" size="mini"></el-input-number>
                      <label class="label bottom">Bottom</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.left" :min="0" :max="300" size="mini"></el-input-number>
                      <label class="label bottom">Left</label>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number controls-position="right" v-model="selectedBlock.content.margin.right" :min="0" :max="300" size="mini"></el-input-number>
                      <label class="label bottom">Right</label>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-collapse-item>
          </template>

          <!-- Responsiveness -->
          <el-collapse-item class="blockOptionsContainer" title="Responsiveness" name="responsive" v-if="!selectedBlock.type.startsWith('columns')">
            <el-row :gutter="20" class="rowOptions" style="margin-top: 10px">
              <el-col :span="12" class="optionItem">
                <el-checkbox v-model="selectedBlock.desktopVisible">Show On Desktop</el-checkbox>
              </el-col>
              <el-col :span="12" class="optionItem">
                <el-checkbox v-model="selectedBlock.mobileVisible">Show On Mobile</el-checkbox>
              </el-col>
            </el-row>
          </el-collapse-item>
        </el-collapse>
      </el-tab-pane>
    </el-tabs>

    <!-- Right Side -->
    <div class="rightSide">
      <div class="mainEditor" :class="{ 'grw-popup-overlay': pupupAppereance.type == 'popup' }" :style="{ 'background-color': pupupAppereance.type == 'popup' ? pupupAppereance.overlayColor : 'transparent' }">
        <!-- When Blocks Found -->
        <div
          class="widgetBox grw-widget"
          v-if="blockList.length > 0"
          :class="{
            'grw-web-popup': pupupAppereance.type == 'popup',
            'grw-web-embed': pupupAppereance.type == 'embed',
            'grw-flyout-container': pupupAppereance.type == 'flyout',
            'grw-flyout-h-left': pupupAppereance.type == 'flyout' && pupupAppereance.flyoutPosition.x == 'left',
            'grw-flyout-h-right': pupupAppereance.type == 'flyout' && pupupAppereance.flyoutPosition.x == 'right',
            'grw-flyout-h-center': pupupAppereance.type == 'flyout' && pupupAppereance.flyoutPosition.x == 'center',
            'grw-flyout-v-top': pupupAppereance.type == 'flyout' && pupupAppereance.flyoutPosition.y == 'top',
            'grw-flyout-v-bottom': pupupAppereance.type == 'flyout' && pupupAppereance.flyoutPosition.y == 'bottom'
          }"
          :style="{ width: pupupAppereance.width + 'px', 'background-color': pupupAppereance.background.color, 'background-image': pupupAppereance.background.image ? 'url(' + pupupAppereance.background.image + ')' : 'none', 'background-position': pupupAppereance.background.imageAlignment, 'background-size': pupupAppereance.background.imageSize, 'border-radius': pupupAppereance.borderRadius + 'px' }"
        >
          <!-- Close Button - Absolute -->
          <div role="button" class="grw-message-close grw-message-close-container" title="Close" aria-label="Close" tabindex="5" :style="{ top: closeButtonPadding, right: closeButtonPadding }">
            <img alt="Close" class="grw-message-close-img" src="https://static.growlytics.in/on-site/popup-close-button.svg" />
          </div>

          <!-- Block List - Iterate -->
          <draggable v-model="blockList" group="blockList" @start="drag = true" @end="drag = false" v-bind="dragOptions" handle=".dragContainer">
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
              <div class="blockContainer" v-for="(block, index) in blockList" :key="index" @click="onBlockSelect(block, block)">
                <!-- Render Block -->
                <div
                  class="block"
                  :class="{
                    'selected-block': selectedBlock && block.id == selectedBlock.id
                  }"
                >
                  <!-- Block - Add Buttons  -->
                  <div class="to-be-removed addButtonContainer addButtonContainerTop blockAddButtonContainer">
                    <div class="liner"></div>
                    <el-button size="mini" type="primary" icon="el-icon-plus" circle class="addButton" @click="showBlockSelector(index)"> </el-button>
                  </div>
                  <div class="to-be-removed addButtonContainer addButtonContainerBottom blockAddButtonContainer">
                    <div class="liner"></div>
                    <el-button size="mini" type="primary" icon="el-icon-plus" circle class="addButton" @click="showBlockSelector(index + 1)"> </el-button>
                  </div>

                  <!--  Move Button -->
                  <el-tooltip class="dragContainer to-be-removed" effect="dark" content="Drag Block Up/Down" placement="left-start">
                    <el-button class="dragContainer to-be-removed" size="mini" type="primary" circle>
                      <i class="fa fa-arrows" aria-hidden="true"></i>
                    </el-button>
                  </el-tooltip>

                  <!--  Delete Button -->
                  <el-tooltip class="deleteButton to-be-removed" effect="dark" content="Delete Block" placement="left-start">
                    <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="removeBlock(index)"></el-button>
                  </el-tooltip>

                  <!-- Copy  Button -->
                  <el-tooltip class="copyButton to-be-removed" effect="dark" content="Copy Block" placement="top">
                    <el-button class="" size="mini" type="primary" icon="el-icon-document-copy" circle @click="copyBlock(index)"></el-button>
                  </el-tooltip>

                  <!-- If Columns -->
                  <div
                    :id="'block_' + block.id"
                    v-if="block.type.startsWith('columns')"
                    class="bock-image-background"
                    :style="{
                      'background-color': block.content.rowBackgroundColor ? block.content.rowBackgroundColor : 'transparent',
                      'background-image': block.content.rowBackgroundImage ? 'url(' + block.content.rowBackgroundImage + ')' : 'none',
                      padding: block.content.rowPadding.top + 'px ' + block.content.rowPadding.right + 'px ' + block.content.rowPadding.bottom + 'px ' + block.content.rowPadding.left + 'px ',
                      'border-radius': getBorderRadiusForBlock(index),
                      overflow: 'hidden'
                    }"
                  >
                    <!-- Hide on mobile/desktop  -->
                    <div class="rowMobileShowHideDiv to-be-removed">
                      <el-tooltip effect="dark" content="Hide Row In Desktop" placement="bottom" v-if="block.hideOnDesktop">
                        <div class="mobileShowHideDiv_btn bg-secondary-600 text-white text-center rounded-md">
                          <i class="fa-regular fa-display"></i>
                          <i class="fa-solid fa-slash"></i>
                        </div>
                      </el-tooltip>
                      <el-tooltip effect="dark" content="Hidde Row In Mobile" placement="bottom" v-if="block.hideOnMobile">
                        <div class="mobileShowHideDiv_btn bg-secondary-600 text-white text-center rounded-md">
                          <i class="fa-regular fa-mobile"></i>
                          <i class="fa-solid fa-slash"></i>
                        </div>
                      </el-tooltip>
                    </div>

                    <div class="grw-row-section">
                      <div class="grw-row-column" v-for="(blockColumnRows, columnIndex) in block.content.columns" :key="columnIndex" :style="{ flex: block.content.flex[columnIndex] }">
                        <!-- Sub Blocks -->
                        <draggable v-model="block.content.columns[columnIndex]" group="subBlock" @start="drag = true" @end="drag = false" v-bind="dragOptions" handle=".column-dragContainer">
                          <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                            <div class="section-column-block" v-for="(subBlock, columnRowIndex) in block.content.columns[columnIndex]" :key="columnRowIndex" @click.stop="onBlockSelect(subBlock, block)" :class="{ 'selected-block': selectedBlock && subBlock.id == selectedBlock.id }">
                              <!-- Move Sub Block Button -->
                              <el-tooltip class="column-dragContainer to-be-removed" effect="dark" content="Drag Up/Down" placement="top">
                                <el-button size="mini" type="primary" circle>
                                  <i class="fa fa-arrows" aria-hidden="true"></i>
                                </el-button>
                              </el-tooltip>

                              <!-- Delete Sub Block Button -->
                              <el-tooltip class="column-deleteButton to-be-removed" effect="dark" content="Delete" placement="top">
                                <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="removeBlockFromColumn(index, columnIndex, columnRowIndex)"></el-button>
                              </el-tooltip>

                              <!-- Sub Block Copy Button -->
                              <el-tooltip class="column-copyButton to-be-removed" effect="dark" content="Create Copy" placement="top">
                                <el-button class="" size="mini" type="primary" icon="el-icon-document-copy" circle @click="copyBlockToColumn(index, columnIndex, columnRowIndex)"></el-button>
                              </el-tooltip>

                              <!-- Sub-Block - Add Buttons  -->
                              <div class="to-be-removed addButtonContainer addButtonContainerTop">
                                <div class="liner"></div>
                                <el-button size="mini" type="primary" icon="el-icon-plus" circle class="addButton" @click="showBlockSelector(index, columnIndex, columnRowIndex)"> </el-button>
                              </div>
                              <div class="to-be-removed addButtonContainer addButtonContainerBottom">
                                <div class="liner"></div>
                                <el-button size="mini" type="primary" icon="el-icon-plus" circle class="addButton" @click="showBlockSelector(index, columnIndex, columnRowIndex + 1)"> </el-button>
                              </div>

                              <!-- Single Row Sub Block -->
                              <WebPopupBlock :isSubBlock="true" :block="subBlock" @click.stop="onBlockSelect(subBlock, block)"></WebPopupBlock>

                              <!-- Bottom - Sub Dropper -->
                              <div class="dropHint" v-if="subBlock.showDroppers">
                                <div class="dropHintText dropHintTextBottom">Drop Here</div>
                              </div>
                            </div>
                          </transition-group>
                        </draggable>

                        <!-- No Sub Divs -->
                        <div class="noSubBlocksDiv to-be-removed" v-if="block.content.columns[columnIndex].length == 0">
                          <div class="description">
                            <el-button type="info" plain @click="showBlockSelector(index, columnIndex, 0)" size="small">Add Block</el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Single Row -->
                  <WebPopupBlock :style="{ 'border-radius': getBorderRadiusForBlock(index) }" v-else :isSubBlock="false" :block="block"></WebPopupBlock>
                </div>
                <!-- </drop> -->
              </div>
            </transition-group>
          </draggable>

          <!-- Switch button to be removed  - Absolute -->
          <div class="to-be-removed thank-you-page-switch" v-if="pupupAppereance.formSubmit.action == 'thankyou'">
            <el-radio-group v-model="isThankYouPage">
              <el-radio-button :label="false">Main Section</el-radio-button>
              <el-radio-button :label="true">Thank You Section</el-radio-button>
            </el-radio-group>
          </div>
        </div>

        <!-- When No Blocks Found - Add First Block -->
        <div
          v-if="blockList.length == 0"
          class="noBlocksDiv widgetBox grw-widget"
          :class="{
            'grw-web-popup': pupupAppereance.type == 'popup',
            'grw-flyout-container': pupupAppereance.type == 'flyout',
            'grw-flyout-h-left': pupupAppereance.type == 'flyout' && pupupAppereance.flyoutPosition.x == 'left',
            'grw-flyout-h-right': pupupAppereance.type == 'flyout' && pupupAppereance.flyoutPosition.x == 'right',
            'grw-flyout-h-center': pupupAppereance.type == 'flyout' && pupupAppereance.flyoutPosition.x == 'center',
            'grw-flyout-v-top': pupupAppereance.type == 'flyout' && pupupAppereance.flyoutPosition.y == 'top',
            'grw-flyout-v-bottom': pupupAppereance.type == 'flyout' && pupupAppereance.flyoutPosition.y == 'bottom'
          }"
          :style="{ width: pupupAppereance.width + 'px', 'background-color': pupupAppereance.background.color, 'background-image': pupupAppereance.background.image ? 'url(' + pupupAppereance.background.image + ')' : 'none', 'background-position': pupupAppereance.background.imageAlignment, 'background-size': pupupAppereance.background.imageSize, 'border-radius': pupupAppereance.borderRadius + 'px' }"
        >
          <div class="description">
            <el-button type="info" plain @click="showBlockSelector(0)">Add First Block</el-button>
          </div>
          <!-- Switch button to be removed  - Absolute -->
          <div class="to-be-removed thank-you-page-switch" v-if="pupupAppereance.formSubmit.action == 'thankyou'">
            <el-radio-group v-model="isThankYouPage">
              <el-radio-button :label="false">Main Section</el-radio-button>
              <el-radio-button :label="true">Thank You Section</el-radio-button>
            </el-radio-group>
          </div>
        </div>
      </div>
    </div>

    <!-- Drag Drop Block Selector Popup -->
    <WebPopupBlockSelector ref="blockSelector" v-on:blockSelected="addBlock"></WebPopupBlockSelector>
  </div>
</template>

<style lang="scss" src="./commonWebPopupStyles.scss"></style>
<style lang="scss" src="./webPopupEditor.scss"></style>

<script>
import webPopupEditorComponent from './webPopupEditorComponent';
export default webPopupEditorComponent;
</script>
